/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 58.8.13-v202406060159
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import * as models from './models';

/**
 * Audit entries
 */
export interface AuditOutputV1 {
    /**
     * A summary of the changed fields after the change was made.
     */
    "afterSummary"?: string;
    /**
     * A summary of the changed fields before the change was made.
     */
    "beforeSummary"?: string;
    /**
     * The type of change that was made (Create, Update, or Delete).
     */
    "changeType"?: ChangeTypeEnum;
    /**
     * The ID of the modified item.
     */
    "itemId"?: string;
    /**
     * The name of the modified item.
     */
    "itemName"?: string;
    /**
     * The type of the modified item.
     */
    "itemType"?: string;
    /**
     * The date and time when the change was made.
     */
    "timeStamp"?: string;
    /**
     * The ID of the user who made the change.
     */
    "userId"?: string;
    /**
     * The username of the user who made the change.
     */
    "userName"?: string;
}

export enum ChangeTypeEnum {
    CREATE = 'CREATE' as any,
    UPDATE = 'UPDATE' as any,
    DELETE = 'DELETE' as any
}
